/*  */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background: #f9fafb;
}

::-webkit-scrollbar-track {
  background: #f3f4f6;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 8px;
  transition: all 0.5s ease-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #a4a4a4;
  border-radius: 8px;
}
